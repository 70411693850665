<template>
  <div class="adviceBox">
    <!-- tabs标签页 -->
    <div>
      <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
        <el-tab-pane label="透析耗材" name="1">
          <consumable ref="consumable"></consumable>
        </el-tab-pane>
        <el-tab-pane label="透析药品" name="2">
          <Dialysis ref="Dialysis"></Dialysis
        ></el-tab-pane>
        <!-- <el-tab-pane label="透析机耗材" name="3">
          <dialysismachine ref="dialysismachine"></dialysismachine
        ></el-tab-pane> -->
      </el-tabs>
    </div>
  </div>
</template>

<script>
import { toRefs, reactive, onMounted, ref } from 'vue'
import consumable from './consumable .vue'
import Dialysis from './Dialysis.vue'
// import dialysismachine from './dialysismachine.vue'
export default {
  components: {
    consumable,
    Dialysis,
    // dialysismachine,
  },
  setup() {
    const state = reactive({
      activeName: '1',
    })
    onMounted(() => {})
    // 调用透析耗材方法
    const consumable = ref()
    // 调用透析药品方法
    const Dialysis = ref()
    // 调用透析机耗材方法
    const dialysismachine = ref()
    // tabs切换
    const handleClick = () => {
      if (state.activeName == '1') {
        consumable.value.tobody()
      } else if (state.activeName == '2') {
        Dialysis.value.tobody()
      } else {
        dialysismachine.value.tobody()
      }
    }

    return {
      ...toRefs(state),
      handleClick,
      consumable,
      Dialysis,
      dialysismachine,
    }
  },
}
</script>

<style scoped lang="scss">
.adviceBox {
  height: 728px;
  background: #ffffff;
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  padding: 10px;
}
</style>
